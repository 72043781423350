.description {
  margin-bottom: 20px;
}

.usage {
  width: 1240px;
  margin-bottom: 10px;
}
// ----------- テーブル上部のボタンやデートピッカー、リンクなどの制御行のスタイル
.tablePreInfo {
  width: 100%;
  max-width: var(--page-contents-width);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  // 日別予約一覧の「日にちリンク」表示位置調整のための余白（カレンダーポップアップと施設名列のz-index調整がうまくいかないため）
  .dummy {
    width: 55px;
  }

  .controlContainer {
    display: flex;
    align-items: flex-end;
    .link {
      margin: 3px 5px;
      a {
        display:flex;
        align-items: center;
        text-decoration: underline;
        color: blue;
      }
    }
    .spacer {
      width: 20px;
    }
  }

  .insertButton {
    width: 150px;
    margin-bottom: 5px;
    padding: 4px 10px;
    font-size: 16px;
    color: var(--insert-button-font-color);
    background-color: var(--insert-button-bg-color);
    border-width: 0;

    &:hover {
      color: white;
      background-color: var(--insert-button-hover-bg-color);
      cursor: pointer;
    }
  }
  @media screen and (max-width: 800px) {
    .insertButton {
      width: 120px;
    }
  }
  @media screen and (max-width: 600px) {
    .insertButton {
      width: 50px;
    }
  }
}

// ----------- テーブル本体のフレーム（スクロールバー表示エリア）
.tableFrame {
  width: 100%;
  max-width: var(--page-contents-width);
  overflow-x: auto;
  overflow-y: auto;

  &.readOnly {
    overflow-x: auto;
    overflow-y: hidden;
  }

  // ----------- テーブルの本体部分

  .table {
    width: var(--page-contents-width);
    margin-bottom: 20px;
    // height: 550px;

    // ヘッダー行、データ行
    .headerRow,
    .bodyRow {
      display: flex;
      .header {
        display: flex;
        align-items: center;
        height: 40px;
        color: var(--reservation-title-row-font-color);
        background-color: var(--reservation-title-row-bg-color);
        .headerCell {
          width: 100%;
          text-align: center;
        }
      }
    }
    .headerRow {
      position: sticky;
      top: 0px;
    }

    // ----------- 全てのデータ行エリア
    .bodyRows {
      position: relative;

      // 各データ行
      .bodyRow {
        border-bottom: solid 0.5px gray;

        // データセル（ヘッダ列にしか適用されていない）
        .body {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 5px;
          background-color: var(--reservation-title-cols-bg-color);

          .bodyCell {
            width: 100%;

            .dateLink {
              color: #003399;
              &:hover {
                text-decoration: underline;
              }
            }
          }

          a {
            text-decoration: underline;
            color: blue;
            &:hover {
              font-weight: bold;
            }
          }
        }

        // 「0時」~「24時」列部分のエリア全体
        .timeCellArea {
          display: flex;
          width: calc(20px * 48);

          // 各30分単位でのセル
          .timeCell {
            border-left: solid 0.5px gray;
            width: 20px;
            height: 40px;

            &:nth-child(4n + 1),
            &:nth-child(4n + 2) {
              background-color: white;

              &:hover {
                cursor: pointer;
                background-color: pink;
              }
            }

            &:nth-child(4n + 3),
            &:nth-child(4n) {
              background-color: var(--reservation-odd-hour-bg-color);

              &:hover {
                cursor: pointer;
                background-color: pink;
              }
            }

            &.disabled {
              background-color: var(--reservation-past-hour-bg-color);
              opacity: 0.3;
              &:hover {
                cursor: auto;
                background-color: var(--reservation-past-hour-bg-color);
              }
            }

            &.readOnly {
              &:hover {
                cursor: default;
                background-color: white;
              }
            }

            // 以下、罫線をひく
            &:last-child {
              border-right: solid 0.5px gray;
            }
          }
        }
      }

      // ----------- フロート表示セルのスタイル
      .reservationArea,
      .reservationAreaOthers,
      .suspendedArea {
        position: absolute;
        border-radius: 5px;
        height: 40px;
        width: 200px;
      }

      // -----------「予約済み」セルのスタイル：ログインユーザーによる予約
      .reservationArea {
        border: solid 2px var(--reservation-reserved-border-color);
        background-color: var(--reservation-reserved-bg-color);

        &:hover {
          cursor: pointer;
          background-color: var(--reservation-reserved-hover-bg-color);
        }

        &.disabled {
          &:hover {
            background-color: var(--reservation-reserved-bg-color);
            cursor: auto;
          }
        }
      }

      // -----------「予約済み」セルのスタイル：ログインユーザー以外による予約
      .reservationAreaOthers {
        border: solid 2px green;
        background-color: lightgreen;

        &:hover {
          cursor: pointer;
          background-color: green;
        }

        &.disabled {
          &:hover {
            background-color: lightgreen;
            cursor: auto;
          }
        }
      }

      // -----------「利用停止」セルのスタイル
      .suspendedArea {
        border: solid 2px gray;
        background-color: lightgray;
      }
    }

    // ============= 罫線をひく
    .header,
    .body {
      border-left: 0.5px solid lightgray;
    }
    .body {
      &:last-child {
        border-bottom: 0.5px solid lightgray;
      }
    }

    // ヘッダー列（インデックス列）の設定
    .index {
      width: 40px;
      justify-content: flex-end;
    }

    // ヘッダー列（施設名/年月日列）の設定
    .titleColumn {
      width: 240px;
    }
    // @media screen and (max-width: 950px) {
    //   .index {
    //     width: 30px;
    //     font-size: 14px;
    //     justify-content: flex-end;
    //   }

    //   // ヘッダー列（施設名/年月日列）の設定
    //   .titleColumn {
    //     width: 150px;
    //     font-size: 14px;
    //   }
    // }

    // １列目と２列目は、横スクロール固定とする
    .colFix1,
    .colFix2 {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    .colFix1 {
      left: 0;
    }

    .colFix2 {
      left: 40px;
      border-right: 1px solid lightgray;
    }
  }

  @media screen and (max-width: 950px) {
    .table {
      width: 1140px;

      .index {
        width: 28px;
        font-size: 14px;
        justify-content: flex-end;
      }

      // ヘッダー列（施設名/年月日列）の設定
      .titleColumn {
        width: 150px;
        font-size: 14px;
      }

      .colFix2 {
        left: 28px;
      }
    }
  }
}
