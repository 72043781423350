.col1 {
  width: 40px;
  text-align: right;
  white-space: nowrap;
}
.col2 {
  width: 370px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.col3 {
  width: 440px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.col4 {
  width: 260px;
  text-align: center;
}
.col5 {
  width: 130px;
  text-align: center;
}
