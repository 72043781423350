// 600px以下を「スマートホン」のサイズとする。
// タブレットは、PCと同じサイズで表示する
@media screen and (max-width: 600px) {
  .detailForm {
    width: 100%;
    .row {
      &.contractorInfo {
        display: none;
      }
      .input {
        &.multiRows {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

// 601px以上でPC版。
.detailForm {
  padding: 0 5px;
  .topMargin {
    height: 40px;
  }
  .topSmallMargin {
    height: 20px;
  }
  .noMargin {
    height: 0px;
  }

  .link {
    color: blue;
    text-decoration: underline;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
  // 900pxを下回ったら、タイトルとデータの列を縦並びにする。
  @media screen and (max-width: 900px) {
    .row {
      flex-direction: column;
    }
  }

  .row {
    display: flex;
    height: auto;
    margin-top: 10px;

    &.breakLine {
      margin-top: 0;
    }
    // &.separator {
    //   width: 100%;
    //   border-bottom: #808080 0.5px solid;
    // }

    .label {
      min-width: 200px;
      padding-top: 5px;
      font-weight: bold;
    }
    .input {
      display: flex;
      align-items: center;
      .row1,
      .row2 {
        display: flex;
        align-items: center;
      }
      &.separator {
        width: 100%;
        border-bottom: #808080 0.5px solid;
      }
    }
    .output {
      display: flex;
      align-items: center;
    }
    .outputLayer {
      margin-top: 5px;
      .innerRow {
        display: flex;
        margin-bottom: 5px;

        .innerLabel {
          width: 150px;
          margin-left: 5px;
        }
        .honorific {
          margin-left: 5px;
        }
      }
    }
    .appendLabel {
      margin-left: 10px;
    }
    &.inlineBlock {
      display: flex;
      margin: 0 5px 15px;
      .inlineMargin {
        margin-right: 10px;
      }
      .emphasis {
        font-size: 20px;
        font-weight: bold;
        color: #ff6600;
      }
    }
  }
  // フッターボタンエリア
  .footerButtons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;

    .button {
      margin: 0 5px;
      width: 150px;
      height: 40px;
      font-size: 18px;
      color: var(--footer-button-font-color);
      background-color: var(--footer-button-bg-color);
      border: 0px;
      &:hover {
        color: white;
        background-color: var(--footer-button-hover-color);
        cursor: pointer;
      }
      &:disabled {
        color: gray;
        background-color: lightgray;
        &:hover {
          color: gray;
          background-color: lightgray;
          cursor: auto;
        }
      }
      &.delete {
        width: 120px;
        margin-left: 100px;
        color: var(--footer-del-button-font-color);
        background-color: var(--footer-del-button-bg-color);
        &:hover {
          color: black;
          background-color: var(--footer-del-button-hover-color);
          cursor: pointer;
        }
      }
      &.clear {
        width: 90px;
        border: 2px var(--footer-button-hover-color) solid;
        color: blue;
        background-color: white;
        &:hover {
          color: black;
          background-color: lightgray;
        }
      }
    }
  }
}
