.pageTitle {
  width: 100%;
  margin-bottom: 20px;
  color: #333;
  text-decoration: underline;
  text-underline-offset: 5px;
  border-radius: 10px;

  text-align: center;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 5px;

  &.descStyle {
    margin-bottom: 10px;
  }
  // max-width: var(--page-contents-width);
  // padding: 7px;
  // background-color: var(--page-title-color);
}

@media screen and (max-width: 600px) {
  .pageTitle {
    letter-spacing: 0;
  }
}
