.customInput {
  width: 140px;
  height: 30px;
  text-align: left;
  margin: 5px;
  padding: 3px 7px 0;
  font-size: 20px;
  border-color: lightgray;
  border-width: 1;
  border-style: solid;
  background-color: var(--common-control-color);
  &:hover {
    cursor: pointer;
  }
}

.datePicker {
  z-index: 10000;
}
