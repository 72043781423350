.col1 {
  width: 40px;
  text-align: right;
}

.col2 {
  width: 220px;
  text-align: left;
}

.col3 {
  width: 60px;
  text-align: center;
}

.col4,
.col5 {
  width: 220px;
  text-align: center;
}

.col6 {
  width: 480px;
  text-align: left;
}
