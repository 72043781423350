.headerContainer {
  width: 100%;
  background-color: var(--header-bg-color);

  .productArea {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .productLogo {
      display: flex;
      flex-direction: column;
      height: 60px;
      .logo {
        margin: 5px 0 0 10px;
        width: 250px;
        height: 60px;
      }
      .logoImage {
        margin: 3px 0 0 25px;
        width: 40px;
        height: 40px;
      }
      .coopImage {
        margin: 2px 0 0 10px;
        width: 70px;
        height: 10px;
      }
    }

    .productName {
      min-width: 180px;
      padding: 5px;
      margin: 5px auto 0 auto;
      font-size: 32px;
      letter-spacing: 10px;
    }
    @media screen and (max-width: 700px) {
      .productName {
        text-align: center;
      }
    }

    .userOverviewInfo {
      .notLoginUserOverview {
        width: 255px;
      }
    }

    @media screen and (max-width: 800px) {
      .userOverviewInfo {
        display: none;
      }
    }

    .hamburgerMenu {
      .hamburger {
        width: 40px;
        height: 40px;
      }
      margin: 10px;
    }
  }

  // 950px以下の場合の表示制御
  @media screen and (max-width: 950px) {
    .mainMenu {
      display: none;
    }
  }

  .mainMenu {
    ul {
      padding: 10px 0;
      display: flex;
      justify-content: center;
      background-color: var(--maim-menu-bg-color);

      li {
        margin: 0 3px;
        width: 150px;
        height: 40px;
        line-height: 37px;
        color: var(--main-menu-button-hover-font-color);
        background-color: var(--main-menu-button-bg-color);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        border: 1px solid blue;

        &:hover {
          cursor: pointer;
          background-color: var(--main-menu-button-hover-bg-color);
          color: white;
          font-weight: 900;
        }
      }
    }
  }
}
