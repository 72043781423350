.expiredMessage {
  width: 100%;
  max-width: var(--page-contents-width);
  margin-bottom: 20px;
  padding: 10px;
  background-color: pink;
  border: red;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
