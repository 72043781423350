// 主に、テーブル上部に配置する「新規登録」ボタンの領域
.tablePreInfo {
  width: 100%;
  max-width: var(--page-contents-width);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;

  .insertButton {
    width: 150px;
    padding: 4px 10px;
    font-size: 16px;
    color: var(--main-menu-button-font-color);
    background-color: var(--main-menu-button-bg-color);
    border-width: 0;

    &:hover {
      color: white;
      background-color: var(--insert-button-hover-bg-color);
      cursor: pointer;
    }
  }
}

.tableFrame {
  width: 100%;
  max-width: var(--page-contents-width);
  overflow-x: hidden;
  overflow-y: auto;

  &.topPageTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: solid 2.5px black;
    margin-bottom: 15px;
    overflow-y: hidden;
  }
  .topPageSwitchList {
    border: none;
    background-color: white;
    &:hover {
      color: blue;
      cursor: pointer;
    }
  }

  &.scrollable {
    height: 220px;
    overflow-y: scroll;
  }
  //
  .topPageTable {
    width: var(--page-contents-width);
    .dataRow {
      display: flex;
      color: #06c;
      .dataColumn {
        padding: 10px 10px 10px 5px;
        border-bottom: 1px solid lightgray;
      }
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
  //
  .commonTable {
    width: var(--page-contents-width);
    border: 1px solid gray;

    .headerRow,
    .dataRow {
      display: flex;

      .headerColumn,
      .dataColumn {
        padding: 5px;
        border: 1px solid lightgray;
      }

      .headerColumn {
        font-weight: 600;
      }
    }

    .headerRow {
      color: var(--main-menu-button-hover-font-color);
      border-bottom: 1px double gray;

      > * {
        background-color: var(--spread-sheet-title-bg-color);
      }
    }

    .dataRow:hover {
      background-color: #ffff99;
      cursor: pointer;
    }
    // ホバー時に選択状態にしない行の場合
    .dataRow.notHover:hover {
      background-color: white;
      cursor: auto;
    }

    .dataRow > .dataColumn {
      .button {
        padding: 2px 15px;
        color: var(--download-button-font-color);
        background-color: var(--download-button-bg-color);
        &:hover {
          background-color: var(--download-button-hover-bg-color);
          // color: black;
          // border-color: #99ccff;
        }
      }
    }

    .bottomDataRow {
      display: flex;
      justify-content: space-between;
      border: 1px solid lightgray;
      padding: 7px;
      width: 100%;
      .button {
        padding: 5px 30px;
        font-size: 16px;
        letter-spacing: 5px;
      }
    }
    .emptyList {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60px;
      background-color: whitesmoke;
      font-size: 20px;
      color: goldenrod;

      &.isShow {
        display: flex;
      }
    }
  }
}
