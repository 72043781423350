.hamburgerMenu {
  .menuCheck {
    display: none;
    // チェックされた時は
    &:checked {
      // 兄弟（同列に存在する）ノードの
      ~ {
        // 三本線アイコンをバツアイコンに変更
        .hamburgerButton {
          span {
            background-color: rgba(255, 255, 25, 0);
          }
          span::before {
            bottom: 0;
            transform: rotate(45deg);
          }
          span::after {
            top: 0;
            transform: rotate(-45deg);
          }
        }
        .dropdownMenu {
          right: 0;
        }
      }
    }
  }

  // ハンバーガーメニューアイコン部分の初期状態の設定
  .hamburgerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40px;
    height: 40px;
    background-color: #3584bb;
    z-index: 5;

    span,
    span:before,
    span:after {
      content: '';
      position: absolute;
      display: block;
      height: 3px;
      width: 25px;
      border-radius: 3px;
      background-color: #ffffff;
    }

    span:before {
      bottom: 8px;
    }
    span:after {
      top: 8px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  //
  .dropdownMenu {
    width: 300px;
    position: fixed;
    top: 0;
    right: -300px;
    background-color: #3584bb;
    opacity: 0.9;
    z-index: 4;
    transition: all 0.3s;

    ul {
      padding: 70px 10px 0;

      li {
        font-size: 20px;
        color: #ffffff;
        margin: 6px 0;
        list-style: none;
        &:hover {
          color: coral;
          font-weight: bold;
          background-color: #4695cc;
        }

        &::before {
          position: absolute;
          content: '';
          width: 7px;
          height: 7px;
          right: 11px;
          margin-top: 14px;
          margin-right: 10px;
          border-top: solid 2px #ffffff;
          border-right: solid 2px #ffffff;
          transform: rotate(45deg);
        }
      }
    }
  }
  .spacer {
    height: 10px;
  }
}
