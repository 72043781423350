.label {
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }

  .radio {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    &:hover {
      cursor: pointer;
    }
  }
}
