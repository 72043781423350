.tableWidth {
  // width: 962px;
}

.col1 {
  width: 50px;
  text-align: right;
}

.col2 {
  width: 620px;
  text-align: left;
}

.col3 {
  width: 120px;
  text-align: center;
}

.col4 {
  width: 250px;
  text-align: center;
}

.col5 {
  width: 80px;
  text-align: center;
}

.col6 {
  width: 120px;
  text-align: center;
}
