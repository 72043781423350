.col1 {
  width: 35px;
  text-align: right;
}
.col2 {
  width: 130px;
}
.col3 {
  width: 250px;
}
.col4 {
  width: 400px;
}
.col5 {
  width: 0px;
}
