.col1 {
  width: 70px;
  text-align: right;
}
.col2 {
  width: 220px;
  text-align: left;
}
.col3 {
  width: 350px;
  text-align: left;
}
.col4 {
  width: 600px;
  text-align: left;
}
