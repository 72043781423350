.inputFile {
  display: flex;
  margin-left: 5px;

  .uploadFileContainer {
    padding: 5px 15px;
    color: white;
    background-color: #384878;
    cursor: pointer;

    .inputFile {
      display: none;
    }
  }
  .fileName {
    padding-top: 3px;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
    max-width: 600px;
    // 3点リーダで切り詰め
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .clearButton {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-right: 10px;
    font-size: 20px;
    font-style: italic;
    color: blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
