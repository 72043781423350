// .col1 {
//   width: 60px;
//   text-align: right;
// }
.col1 {
  width: 240px;
  text-align: left;
  // font-size: 20px;
}
.col2 {
  width: 1000px;
  text-align: left;
}
// .col3 {
//   width: 170px;
//   text-align: center;
// }
