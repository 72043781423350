.selectContainer {
  padding: 5px;

  .select {
    height: 30px;
    width: 100px;
    font-size: 20px;
    background-color: var(--common-control-color);

    option {
      width: 100%;
    }
  }
}
