.header {
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: var(--page-contents-width);
  padding: var(--page-common-padding);
  margin: 0 auto;
}

.footer {
  width: 100%;
}
