.adminCol1 {
  width: 40px;
  text-align: right;
}
.adminCol2 {
  width: 230px;
  text-align: center;
}
.adminCol3 {
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adminCol4 {
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.col1 {
  width: 240px;
  text-align: left;
}
.col2 {
  width: 1000px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.col3 {
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
