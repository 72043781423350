.checkboxContainer {
  margin-left: 5px;
  padding: 5px;
  .label {
    display: flex;
    align-items: center;

    width: 100%;
    margin-right: 20px;
    &:hover {
      cursor: pointer;
    }

    .checkbox {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.noLeftMargin {
  margin-left: 0px;
}
