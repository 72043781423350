/* 余白などをクリア */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  /* Window幅系 */
  --page-contents-width: 1240px;
  --smartphone-upper-width: 600px;
  --page-common-padding: 20px;
  /* カラー系 */
  --common-font-color: #333333;
  --common-control-color: lightgoldenrodyellow;
  /* カラー系：ページタイトル*/
  --page-title-color: #ffcc99;
  /* カラー系：ヘッダー、フッター */
  --header-bg-color: #f2f2f5;
  --footer-bg-color: #f2f2f5;
  --maim-menu-bg-color: lightgray;
  --main-menu-button-font-color: white;
  --main-menu-button-bg-color: #5c8bc6;
  --main-menu-button-hover-font-color: white;
  --main-menu-button-hover-bg-color: #3366cc;
  /* --main-menu-button-color: #99ccff; */

  /* カラー系：新規登録ボタン */
  --insert-button-font-color: white;
  --insert-button-bg-color: #5c8bc6;
  --insert-button-hover-bg-color: #3366cc;
  /* カラー系：フッターボタン（登録、更新など）*/
  --footer-button-font-color: white;
  --footer-button-bg-color: #5c8bc6;
  --footer-button-hover-color: #3366cc;
  /* カラー系：フッターボタン（削除）*/
  --footer-del-button-font-color: white;
  --footer-del-button-bg-color: #696969;
  --footer-del-button-hover-color: #d3d3d3;
  /* カラー系：フッターボタン（クリア）*/
  --clear-button-font-color: white;
  --clear-button-bg-color: #5c8bc6;
  --clear-button-hover-font-color: #3366cc;
  --clear-button-hover-bg-color: #3366cc;
  /* カラー系：ダウンロードボタン */
  --download-button-font-color: white;
  --download-button-bg-color: #5c8bc6;
  --download-button-hover-bg-color: #3366cc;


  /* カラー系：スプレッドシートタイトル背景色 */
  --spread-sheet-title-font-color: white;
  /* カラー系：スプレッドシートタイトル背景色 */
  --spread-sheet-title-bg-color: #5c8bc6;

  /* カラー系：予約一覧 */
  /* 予約一覧：タイトル行文字色 */
  --reservation-title-row-font-color: white; 
  /* 予約一覧：タイトル行背景色 */
  --reservation-title-row-bg-color: #5c8bc6;
  /* 予約一覧：タイトル列背景色 */
  /* --reservation-title-cols-bg-color: lightgoldenrodyellow; */
  --reservation-title-cols-bg-color: white;
  /* 予約一覧：奇数時間の列背景色 */
  /* --reservation-odd-hour-bg-color: lightgoldenrodyellow; */
  --reservation-odd-hour-bg-color: white;
  /* 予約一覧：過去日時（予約不可）セルの背景色 */
  --reservation-past-hour-bg-color: lightgray;
  /* 予約一覧：予約済みセルの背景色 */
  --reservation-reserved-bg-color: lightcoral;
  --reservation-reserved-hover-bg-color: red;
  --reservation-reserved-border-color: red;
  /* 予約一覧：予約不可（管理者による制限）セルの背景色 */
  --reservation-suspend-hour-bg-color: gray;
  --reservation-suspend-hour-hover-bg-color: gray;
  --reservation-suspend-hour-border-color: gray;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  color: var(--common-font-color);
}

/* リスト系：プレフィックス文字は非表示とする */
ul,
ol {
  list-style: none;
}

/* （共通）リンクは全ての状態で下線なし通常文字色を標準とする。 */
a {
  text-decoration: none;
  &:visited,
  &:active,
  &:link {
    color: var(--common-font-color);
  }
}
