.headerRow {
  display: flex;
  width: calc(40px * 24);

  .col {
    padding-left: 3px;
    line-height: 2.5;
    width: 40px;
    height: 40px;
    color: var(--spread-sheet-title-font-color);
    background-color: var(--spread-sheet-title-bg-color);

    border-top: solid 0.5px gray;
    border-right: solid 0.5px gray;
  }
}
