.col1 {
  width: 40px;
  text-align: right;
}
.col2 {
  width: 80px;
  text-align: center;
}
.col3 {
  width: 400px;
  text-align: left;
}
.col4 {
  width: 150px;
  text-align: center;
}
.col5 {
  width: 150px;
  text-align: center;
}
.col6 {
  width: 120px;
  text-align: center;
}
.col7 {
  width: 300px;
  text-align: left;
}
.col8 {
  width: 150px;
  text-align: left;
}
.col9 {
  width: 150px;
  text-align: center;
}
