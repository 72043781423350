.labelContainer {
  display: flex;
  align-items: center;

  .label {
    display: inline-block;
    font-size: 18px;
  }
  .required {
    display: inline-block;
    margin-left: 5px;
    line-height: 1;
    color: red;
    font-size: 26px;
    font-weight: bold;
  }
}
